import React from "react";
import { P1 } from "app/components/Typography";

export default () => (
  <>
    <P1
      mx="auto"
      color="monochrome.6"
      textAlign="center"
      mt={{ _: "123px", lg: "183px" }}
    >
      You have no videos posted.
      <br /> Videos you submit can be found here!
    </P1>
  </>
);
